import { query, collection, orderBy, getDocs, deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from "./firebase";
import * as _ from "lodash"; // TODO fix import
import { Note, UpdateNote } from "../types/Note";
import {v4 as uuidv4} from 'uuid';
import { AuthAtom } from "../components/atoms";

class firestore {

  list = async (user: AuthAtom): Promise<Note[]> => {
      const q = query(collection(db, `noter/${user.uuid}/notes/`), orderBy("createdAt", "desc"));
      const snapshot = await getDocs(q);
      const itemsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } ));

      return itemsData as Note[];
  }

  getById = async (user: AuthAtom, id: string): Promise<Note> => {
    const snapshot = await getDocs(collection(db, `noter/${user.uuid}/notes/`));
    const itemsData = snapshot.docs
      .filter((doc) => doc.id === id)
      .map(doc => ({
        id: doc.id,
        ...doc.data()
      } ));
    return itemsData[0] as Note;
  }

  deleteById = async (user: AuthAtom, id: string): Promise<void> => {
    deleteDoc(doc(db, `noter/${user.uuid}/notes/`, id));
  }

  save = async (user: AuthAtom, note: Note): Promise<string> => {
    if (note.id !== '') {
      return await this.update(user, note);
    }
    note.id = uuidv4();
    return await this.add(user, note);
  }

  add = async (user: AuthAtom, note: Note): Promise<string> => {
    await setDoc(doc(db, `noter/${user.uuid}/notes/${note.id}`), note);
    return note.id;
  }

  getLabels = async (user: AuthAtom): Promise<string[]> => {
    const docs = await this.list(user);
    let labels: string[] = [];
    for (const doc of docs) {
      labels.push(...doc.labels);
    }
    return _.uniq(labels)

  }

  async update(user: AuthAtom, updateNote: UpdateNote): Promise<string> {
    const note = await this.getById(user, updateNote.id);
    const toUpdate = {...note, ...updateNote};
    return await this.add(user, toUpdate);
  }

}

export const firestoreService = new firestore();