import { useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Slide, Box } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { Logout } from "@mui/icons-material";
import { Link } from "react-router-dom";
import packageJson from '../../package.json';
import { authAtomState } from "./atoms";
import { useAtom } from "jotai";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export const Profile = () => {
    const [user] = useAtom(authAtomState);
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };
    return <>
        <img height="30" src={user.photoURL} alt={user.email} style={{borderRadius: '50%', verticalAlign: 'bottom'}} onClick={handleClickOpen} />
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Your profile</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <Box>
                    <label>Email:</label> {user.email}
                  </Box>
                  <Box>
                    <label>version:</label> {packageJson.version}
                  </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{marginRight: 2}}
                        component={Link}
                        to="/logout"
                        >
                        <Logout />
                    </Button>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    </>
}