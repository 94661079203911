import './App.css';
import { Header } from './components/Header';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Container, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import theme from './theme';
import { Intro } from './components/Intro';
import { QueryClient, QueryClientProvider } from 'react-query';
import { authAtomState } from './components/atoms';
import { useAtom } from 'jotai';

export const App = () =>  {
    const [authAtom] = useAtom(authAtomState)

    const queryClient = new QueryClient();

    return (<>
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <Container>
                <Box className="App">
                    <Header/>
                    {authAtom.authenticated && <Outlet />}
                    {!authAtom.authenticated && <Intro />}
                </Box>
            </Container>
          </QueryClientProvider>
        </ThemeProvider>
    </>);
}

export default App;
