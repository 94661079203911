import { atom } from "jotai";

export type AuthAtom = {
    authenticated: boolean;
    role: string;
    email?: string;
    uuid?: string;
    photoURL?: string;
}

export const authAtomInitial: AuthAtom = {
    authenticated: false,
    role: "",
    email: "",
    uuid: "",
    photoURL: ""
};
export const authAtomState = atom<AuthAtom>(authAtomInitial);