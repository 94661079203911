import { useNavigate } from "react-router-dom";
import { auth } from "../infrastructure/firebase";
import { authAtomInitial, authAtomState } from "./atoms";
import { useAtom } from "jotai";

export const Logout = () => {
    const navigate = useNavigate();
    const [, setAuthAtom] = useAtom(authAtomState);

    auth.signOut().then(() => {
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });

    setAuthAtom(authAtomInitial);
    navigate("/");
    return <></>
}