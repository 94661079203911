import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Labels } from "../Labels";
import { firestoreService } from "../../infrastructure/firestore";
import ReactQuill  from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
// import { DeleteNoteButton } from "./DeleteNoteButton";
import { Note } from "../../types/Note";
import { authAtomState } from "../atoms";
import { useAtom } from "jotai";

interface IFormValues {
  selectedLabels: string[];
  noteText: string;
  noteTitle: string;
}
export const Edit = () => {
  const { id } = useParams();
  const [user] = useAtom(authAtomState);
  const [note, setNote] = useState<Note>();
  const navigate = useNavigate();

  const {handleSubmit, control, reset, register} = useForm<IFormValues>({
    defaultValues: {
      selectedLabels: [],
      noteText: ""
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const fetchedNote = await firestoreService.getById(user, id);
        setNote(fetchedNote);
        reset({
          selectedLabels: fetchedNote.labels,
          noteText: fetchedNote.note
        });
      } else {
        setNote({
          id: '',
          note: '',
          labels: [],
          createdAt: 0
        });
      }
    };
    fetchData();
  }, [user, id, reset]);

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    const saveNote = {
      id: note!.id,
      title: data.noteTitle,
      note: data.noteText,
      labels: data.selectedLabels,
      createdAt: Date.now()
    };
    await firestoreService.save(user, saveNote);
    navigate(`/notes/list`);
  }


  // <Box component="fieldset" sx={{ width: '200px', backgroundColor: '#A9A9A9', borderRadius: 2, border: 0, margin: 2 }}>

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
        {note && <>
          <Box sx={{ width: '700px', height: '400px', backgroundColor: '#A9A9A9', borderRadius: 2, border: 0, margin: 2 }}>

            {/* todo: style this */}
            <input type="text"  {...register("noteTitle")} value={note.title} />

            <Controller
              name="noteText"
              control={control}
              render={({ field }) => (
                <ReactQuill
                  style={{ height: '300px', textAlign: 'left', padding: '10px' }}
                  value={field.value}
                  theme="snow"
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
          <Box>
              <Labels labels={note.labels} control={control} />
              <Button
                style={{ marginTop: '10px' }}
                type="submit"
                variant="outlined"
                color="primary"
              >
                submit
              </Button>
              {/* <DeleteNoteButton id={note.id} /> */}
            </Box>
          </>}
        </Box>
      </form>
  );
}