import { firestoreService } from "../../infrastructure/firestore";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { AsHtml } from "../AsHtml";
import { useQuery } from "react-query";
import { authAtomState } from "../atoms";
import { useAtom } from "jotai";

export const Read = () => {
    const { id } = useParams();
    const [user] = useAtom(authAtomState);

    const {data: note, isLoading} = useQuery({
        queryKey: ['note'],
        queryFn: async () => {
            return await firestoreService.getById(user, id!);
        },
    });

    if (isLoading) {
        return <Box>Loading...</Box>;
    }
    return <Box sx={{textAlign: 'justify'}}>
        <h1>{note!.title}</h1>
        <style>{`
            p > br {
            display: none
            }
            p:not(:last-of-type) {
                margin-bottom: 1rem
            }
        `}
        </style>
        <AsHtml html={note!.note!}/>
    </Box>
}