import React from 'react';
import { Navigate } from 'react-router-dom';
import { authAtomState } from '../components/atoms';
import { useAtom } from 'jotai';

export const ProtectedRoute = (props: { children: React.ReactNode }) => {
  const [auth] = useAtom(authAtomState);

  if (!auth.authenticated) {
    return <Navigate to="/unauthorized" />
  }
  if (auth.role !== 'editor') {
    return <Navigate to="/unauthorized" />
  }
  return <>{props.children}</>;
};

export default ProtectedRoute;