import { Button } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from "react-router-dom";
import { googleAuth } from "../infrastructure/firebase";
import { Profile } from "./Profile";
import GoogleButton from "react-google-button";
import { useAtom } from "jotai";
import { authAtomState } from "./atoms";

export const Header = () => {
  const [autAtom, setAuthAtom] = useAtom(authAtomState)
  const navigate = useNavigate();

  const googleLogin = async () => {

    const user = await googleAuth();
    if (typeof user.role !== 'string') {
      navigate("/unauthorized");
      return;
    }

    setAuthAtom({
      authenticated: true,
      role: user.role,
      email: user.email,
      photoURL: user.photoURL ?? '',
      uuid: user.uuid
    });

    if (!autAtom.authenticated) {
      navigate("/unauthorized");
    }
    navigate("/notes/list");
  }

  return (
    <div style={{margin: '0px 50px 0px 50px'}}>
    <nav>
      <ul style={{display: 'flex', gap: '20px', listStyleType: 'none'}}>
        <li>
          <Button
            variant="contained"
            color="primary"
            sx={{marginRight: 2}}
            component={Link}
            to={autAtom.authenticated ? '/home' : '/'}
          >
            <HomeIcon fontSize="medium" />
          </Button>
        </li>
        {!autAtom.authenticated &&
          <li style={{marginLeft: 'auto'}}>
            <GoogleButton
              type="dark"
              label="Sign in"
              onClick={() => googleLogin() }
            />
          </li>
        }
        {autAtom.authenticated &&
          <li>
            <Button
              variant="contained"
              color="primary"
              sx={{marginRight: 2}}
              component={Link}
              to="/notes/list"
            >
              <FormatListBulletedIcon/>
            </Button>
          </li>}
          {autAtom.authenticated && <li>
            <Button
              variant="contained"
              color="primary"
              sx={{marginRight: 2}}
              component={Link}
              to="/notes/create"
            >
              <AddIcon/>
            </Button>
          </li>}
          {autAtom.authenticated && <li style={{marginLeft: 'auto'}}><Profile /></li>
        }
        </ul>
    </nav>
    </div>
  );
}